import React from "react";

class ImageComponent extends React.Component {
 
  render() {
    const divStyle = {
    backgroundColor: this.props.item.backgroundColor,
      width: "100%",
      height: "100%",
      transition: "all 250ms ease",
    backgroundImage: `url(${this.props.item.backgroundImage})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
    };

    return (
      <>
        <div style={divStyle}>
          
        </div>
      </>
    );
  }
}

export default ImageComponent;
