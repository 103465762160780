import React from "react";
import { fetchWeather, getCity } from "../../api/Api";
import * as meteoIcons from "../../styles/MeteoIcons";
class Weather extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      city: "",
      meteoString: "",
      temperature: "",
      icon: "",
    };
  }

  componentDidMount() {
    this.setWeather()
    this.interval = setInterval(this.setWeather.bind(this), 360*60*1000);
  }
  componentWillUnmount() {
    // Clear the interval right before component unmount
    clearInterval(this.interval);
  }
  setWeather() {
    getCity(this.props.currentDisplay)
      .then((res) => {
        this.setState({ city: res });
      })
      .then(() => {
        fetchWeather(this.state.city).then((res) => {
          this.setState({ meteoString: res.weather[0].description });
          this.setState({ temperature: res.main.temp });
          this.setState({ icon: res.weather[0].icon });
        });
      });


  }



  getIconSvg() {
    if (this.state.icon) {
      const svgIndex = "Svg" + this.state.icon.toUpperCase();

      return meteoIcons[svgIndex];
    }
    return null;
  }

  render() {
    const divStile = {
      overflow: "hidden",
      padding: "0",
      backgroundColor: this.props.item.background,
      width: "100%",
      height: "100%",
      fontFamily: this.props.item.textFont,
      color: this.props.item.color,
      fontSize: this.props.item.textSize + "px",
    };
    const IconStyle = {
      fill: this.props.item.color,
      height: this.props.item.iconSize,
      lineHeight: "0",
      margin: "0",
    };
    const weatherStyle = {
      fontSize: this.props.item.textSize + "px",
      margin: "0",
    };
    return (
      <>
        <div className="container_component">
          <div style={divStile}>
            <h1 style={IconStyle}>{this.getIconSvg()}</h1>
            <h3 style={weatherStyle}>
              <strong>{this.state.meteoString}</strong>
              <br />
              {this.state.temperature} C°
            </h3>
          </div>
        </div>
      </>
    );
  }
}

export default Weather;
