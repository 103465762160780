import React from "react";
import FontList from "../../styles/FontList";

class TitleText extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      color_bg: this.props.item.backgroundColor,
      color_text: this.props.item.textColor,
      text: this.props.item.text,
      fontfamily: this.props.item.fontFamily,
      fontsize: this.props.item.fontSize,
    };
  }

  render() {
    const divStyle = {
      overflow: "hidden",
      backgroundColor: this.props.item.backgroundColor,
    };

    const titleStyle = {
      padding: "10px",
      width: "max-content",
      minWidth: "100%",
      color: this.props.item.textColor,
      wordBreak: "break-word",
      fontSize: this.props.item.fontSize + "px",
      display: "block",
      fontFamily: this.props.item.fontFamily,
      lineHeight: 1,
      marginBottom: "0",
      textAlign: this.props.item.textAlign,
      alignSelf: "start",
    };

    return (
      <>
        <div className="container_component " style={divStyle}>
          <h1 className="text_title" style={titleStyle}>
            {this.props.item.text}
          </h1>
        </div>
      </>
    );
  }
}

export default TitleText;
