var getUrl = window.location;

export var apiLink = getUrl .protocol + "//" + getUrl.host + "/" + "api/";
export var dashboardLink = getUrl .protocol + "//" + getUrl.host + "/apidash/";

//export var apiLink = "http://localhost:81/api/"

if (process.env.REACT_APP_DEBUG === "true") {
 apiLink = "http://127.0.0.1:81/api/"
 dashboardLink = "http://127.0.0.1:81/dashboard_disp/"
}
