import React from "react";
import { Image} from "react-bootstrap";

class Logo extends React.Component {
 
  render() {
    const divStyle = {
      width: "100%",
      height: "100%",
      borderRadius:"100%",
      transition: "all 250ms ease",
      boxShadow: "5px 10px 30px rgba(0,0,0,.2)",
      backgroundColor:"#fff",
      padding: "15%",
    };


    return (
      <>
        <div style={divStyle}>
        
            <Image
            src={ this.props.item.image }
            fluid
            className="w-100 h-100 m-auto component_logoimg" /> 
        </div>

      
      </>
    );
  }
}

export default Logo;
