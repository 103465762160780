import './App.css';
import '../node_modules/react-grid-layout/css/styles.css';
import '../node_modules/react-resizable/css/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/FontList.css'
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Display from './pages/Display';
import RedirectToDisplay from './pages/RedirectToDisplay'
import DisplayPortrait from './pages/DisplayPortrait'


function App() {
  return (
    <Router>
    <Switch>
        
      <Route path="/displaylandscape" render={(props) => <Display {...props}/>} />
      <Route path="/displayportrait" render={(props) => <DisplayPortrait {...props}/>} />
        <Route path="/">
          <RedirectToDisplay />
        </Route>
        
    </Switch>
  </Router>
  );
}

export default App;
