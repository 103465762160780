import React from "react";
import { Image } from "react-bootstrap";
import { getRegionByID, getValueLive } from "../../api/Api";

class FluidityRectangle_Land extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id_zone: this.props.item.zona,
    };
  }

  componentDidMount() {
    this.confirmZone();
    // set Interval
    this.interval = setInterval(this.confirmZone.bind(this), 60*1000);
  }

  componentWillUnmount() {
    // Clear the interval right before component unmount
    clearInterval(this.interval);
  }

  //Get Region
  //getRegionByID
  confirmZone = () => {
    const id_zone = this.state.id_zone;

    getValueLive(id_zone).then((res) => {
      if (res == null) {
        this.setState({ zone_valuelive:0 });
      }else{
      this.setState({ zone_valuelive: res[0].value_live });
}
    }).then(()=>{

    getRegionByID(id_zone).then((res) => {
      if (res[0]) {
        this.setState({ zone_name: res[0].name });
        this.setState({
          capacity: (100 / res[0].max_capacity) * this.state.zone_valuelive,
        });

        if (
          this.state.capacity < res[0].fluidity_conf.fluidity_triggers[1].level
        ) {
          this.setState({
            fluidity_text: res[0].fluidity_conf.fluidity_triggers[0].text,
          });
          this.setState({
            line_color: res[0].fluidity_conf.fluidity_triggers[0].color,
          });
        } else if (
          this.state.capacity < res[0].fluidity_conf.fluidity_triggers[2].level
        ) {
          this.setState({
            fluidity_text: res[0].fluidity_conf.fluidity_triggers[1].text,
          });
          this.setState({
            line_color: res[0].fluidity_conf.fluidity_triggers[1].color,
          });
        } else if (this.state.capacity < 100) {
          this.setState({
            fluidity_text: res[0].fluidity_conf.fluidity_triggers[2].text,
          });
          this.setState({
            line_color: res[0].fluidity_conf.fluidity_triggers[2].color,
          });
        } else {
          this.setState({
            fluidity_triggers: res[0].fluidity_conf.fluidity_triggers,
          });
          this.setState({ fluidity_triggers: res[0].max_capacity });
        }
      } else {
        this.setState({ line_color: "#d6d6d6" });
        this.setState({ capacity: 0 });
        this.setState({ zone_name: "ZONE NAME" });
        this.setState({ fluidity_text: "" });
      }
    });
  })
  };

  render() {
    const divStyle = {
      overflow: "hidden",
      padding: "15px 0",
      backgroundColor: this.props.item.background,
      width: this.props.item.widthSize * 10 + "px",
      height: this.props.item.heightSize * 10 + "px",
      fontFamily: this.props.item.textFont,
      color: this.props.item.textColor,
      fontSize: this.props.item.textSize + "px",
      boxShadow: " 5px 10px 30px rgba(0,0,0,.2)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    };
    const TextStyle = {
      fill: this.props.item.textColor,
      fontSize: this.props.item.textSize + "px",
    };
    const imageStyle = {
      display: "flex",
       height: "60%",
      flex: "2",
     maxHeight: "60%",
     minHeight: "60%",
      width: "100%",
      objectFit: "cover",
      backgroundColor: "#d6d6d6",
    };
    const ProgressContainer = {
      height: "100%",
    };
    const ProgressStyle = {
      backgroundColor: this.state.line_color,
      width: this.state.capacity + "%",
    };

    const headStyle = {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
      padding: "5px 20px",
      // height: "80px",
      // maxHeight: "80px",
     minHeight: "80px",
    };

    const CircleStyle = {
      backgroundColor: this.state.line_color,
    };
    return (
      <>
        <div>
          <div style={divStyle}>
            <div style={headStyle}>
              <h1 style={TextStyle}>
                <strong>{this.state.zone_name}</strong>
              </h1>
              <h1  style={TextStyle}>{this.state.fluidity_text}</h1>
            </div>
            <Image src={this.props.item.image} style={imageStyle} />
            <div style={ProgressContainer} className="progress_container">
              <div className="progress progress-rectangle">
                <div
                  className="progress-bar"
                  role="progressbar"
                  aria-valuenow={this.state.capacity}
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={ProgressStyle}
                ></div>
              </div>

              <div className="circle-fluidity" style={CircleStyle}></div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default FluidityRectangle_Land;
