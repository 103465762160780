import React from "react";
const months = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre",
];
const days = [
  "Dimanche",
  "Lundi",
  "Mardi",
  "Mercredi",
  "Jeudi",
  "Vendredi",
  "Samedi",
];
class DateAndTime extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      curTime: "loading...",
    };
  }

  componentDidMount() {
    this.timerID = setInterval(() => {
      const d = new Date();
      const monthIndex = d.getMonth();
      const monthName = months[monthIndex];
      const dayNumber = d.getDate();
      const dayName = days[d.getDay()];
      const time =
        ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
      this.setState({
        curTime: [time, dayName, dayNumber, monthName],
      });
    }, 1000);
  }

  componentWillUnmount() {
    // Clear the interval right before component unmount
    clearInterval(this.timerID);
  }

  render() {
    const divStyle = {
      overflow: "hidden",
      padding: "0",
      backgroundColor: this.props.item.background,
      width: "100%",
      height: "100%",
      fontFamily: this.props.item.textFont,
      color: this.props.item.color,
      fontSize: this.props.item.textSize + "px",
    };
    const timeStyle = {
      fontSize: this.props.item.fontsizeTime + "px",
      margin: "0",
      lineHeight: 1,
    };
    const dateStyle = {
      fontSize: this.props.item.textSize + "px",
      margin: "0",
    };
    return (
      <>
        <div className="container_component">
          <div style={divStyle}>
            <h1 style={timeStyle}> {this.state.curTime[0]}</h1>
            <h3 style={dateStyle}>
              {this.state.curTime[1]} {this.state.curTime[2]}
              <br />
              {this.state.curTime[3]}
            </h3>
          </div>
        </div>
      </>
    );
  }
}

export default DateAndTime;
