import React from "react";

import { getFoodGroupByID, getfooditemsbyfoodgroupID } from "../../api/Api";
class MultiFoodGroup_Land extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      color_bgicon: this.props.item.colorBackgroundIcon,
      color_listBg: this.props.item.backgroundList,
      color_icon: this.props.item.colorIcon,
      color_bg: this.props.item.background,
      color_text: this.props.item.colorText,
      color_textName: this.props.item.colorTextName,
      fontsizeFoodItem: this.props.item.fontSizeFoodItem,
      fontfamilyName: this.props.item.fontFamilyName,
      color_textPrice: this.props.item.colorPrice,
      fontfamilyPrice: this.props.item.fontFamilyPrice,
      color_line: this.props.item.colorLine,
      text: this.props.item.text,
      fontfamily: this.props.item.fontFamily,
      fontsize: this.props.item.fontSizeTitle,
      multiFoodGroup: this.props.item.multiFoodGroup,
    };
  }

  componentDidMount() {
    this.multiFoodGroup()
    this.interval = setInterval(this.multiFoodGroup.bind(this), 180 * 10000);

  }
  multiFoodGroup() {
    this.props.item.multiFoodGroup.map((value) => this.getFoodItems(value.key));
    this.MultiFoodGroup = this.props.item.multiFoodGroup.map((value) =>
      this.getFoodItems(value.key)
    );
  }

  componentWillUnmount() {
    // Clear the interval right before component unmount
    clearInterval(this.interval);
  }

  //get FOOD Item FROM FOOD GROUPID & FOOD GROUP INFO
  getFoodItems(keyId) {
    let key = keyId;

    //  FOOD GROUP INFO
    getFoodGroupByID(this.props.item.multiFoodGroup[key].id_foodgroup).then(
      (res) => {
        if (res) {
          this.setState((prevState) => ({
            multiFoodGroup: prevState.multiFoodGroup.map((el) =>
              el.key === key ? { ...el, res: res } : el
            ),
          }));
        } else {
          this.setState((prevState) => ({
            multiFoodGroup: prevState.multiFoodGroup.map((el) =>
              el.key === key
                ? {
                  ...el,
                  res: "",
                }
                : el
            ),
          }));
        }
      }
    );

    //get FOOD Item FROM FOOD GROUPID
    getfooditemsbyfoodgroupID(
      this.props.item.multiFoodGroup[key].id_foodgroup
    ).then((res) => {
      if (res) {
        this.setState((prevState) => ({
          multiFoodGroup: prevState.multiFoodGroup.map((el) =>
            el.key === key
              ? {
                ...el,
                food_items: res,
              }
              : el
          ),
        }));
      }
    });
  }

  svgRender(svgPicture) {
    if (svgPicture) {
      let svgClear = svgPicture.replace(/\\/g, "").replace("<style>", "");

      return (
        <div
          className="svgTitle"
          dangerouslySetInnerHTML={{ __html: svgClear }}
        />
      );
    }
  }

  getmaxFoodItem() {
    let numberFoodGroup = this.state.multiFoodGroup.filter(function (value) {
      return value.id_foodgroup !== "";
    });
    if (
      numberFoodGroup.filter(function (value) {
        return value.id_foodgroup !== "" && value.food_items.length > 0;
      }).length > 4
    ) {
      return 4;
    } else {
      return 8;
    }
  }
  //Capitalize
  capitalize(s) {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
  }
  render() {
    const divStyle = {
      height: "100%",
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignContent: "baseline",
      justifyContent: "center",
    };

    const foodGroupStyle = {
      marginTop: " -30px",
      paddingTop: "40px",
      boxShadow: " 5px 10px 30px rgba(0,0,0,.2)",
      backgroundColor: this.state.color_listBg,
    };
    const containerStyle = {
      display: "block",
      minWidth: "22%",
      maxWidth: "50%",
      flex: "1 1 21%",
      maxHeight: "100%",
      margin: "40px 15px",
    };
    const titlebarStyle = {
      boxShadow: " 5px 10px 30px rgba(0,0,0,.2)",
      width: "min-content",
      maxWidth: "500px",
      height: "60px",
      backgroundColor: " #fff",
      display: " inline-flex",
    };

    const iconContainer = {
      padding: "6px",
      minWidth: "60px",
      maxWidth: "60px",
      backgroundColor: this.state.color_bgicon,
      fill: this.state.color_icon,
    };

    const titleStyle = {
      width: "min-content",
      maxWidth: "500px",
      color: this.state.color_text,
      backgroundColor: this.state.color_bg,
      fontSize: this.state.fontsize + "px",
      display: "block",
      justifyContent: "center",
      fontFamily: this.state.fontfamily,
    };

    const rowItemStyle = {
      margin: " 5px 20px",
      display: "flex",
      placeContent: " space-between",
      borderBottomColor: this.state.color_line,
    };

    const nameStyle = {
      color: this.state.color_textName,
      fontSize: this.state.fontsizeFoodItem + "px",
      fontFamily: this.state.fontfamilyName,
      // textOverflow: "ellipsis",
      // overflow: "hidden",
      // whiteSpace: "nowrap",
    };
    const pricesStyle = {
      marginLeft: "10px",
      fontSize: this.state.fontsizeFoodItem + "px",
      color: this.state.color_textPrice,
      fontFamily: this.state.fontfamilyPrice,
    };
    const imageVector = {
      height: "100%",
      display: "flex",
      alignContent: "center",
    };

    return (
      <>
        <div className="container_component " style={divStyle}>
          {this.state.multiFoodGroup &&
            this.state.multiFoodGroup
              .filter(function (value) {
                return value.id_foodgroup !== "" && value.food_items.length > 0;
              })
              .filter(function (value) {
                let listfoodItemActive = value.food_items[0]
                  ? value.food_items.filter(function (foodItem) {
                    return foodItem.active != false;
                  })
                  : null;
                console.log(listfoodItemActive);

                if (listfoodItemActive) {
                  return listfoodItemActive.length > 0;
                }
              })
              .map((foodGroup, i) => {
                return (
                  <div style={containerStyle}>
                    <div style={titlebarStyle}>
                      <div
                        className="icon_container resizeSVG "
                        style={iconContainer}
                      >
                        <div style={imageVector}>
                          {this.svgRender(foodGroup.res.picto)}
                        </div>
                      </div>
                      <div className="text_title" style={titleStyle}>
                        <h1>{foodGroup.res.name}</h1>
                      </div>
                    </div>
                    <div style={foodGroupStyle} className="multiFoodGroupList">
                      {foodGroup.food_items[0] &&
                        foodGroup.food_items

                          .filter(function (value) {
                            return value.active !== false;
                          })
                          .slice(0, this.getmaxFoodItem())
                          .map((foodItem, i) => {
                            return (
                              <div style={rowItemStyle}>
                                <h3 style={nameStyle}>{foodItem.name} </h3>
                                {foodItem.prices == null ? null : (
                                  foodItem.prices[0].value == 0 || foodItem.prices[0].value == null ? null : (
                                    <h3 style={pricesStyle}>
                                      {/* {foodItem.prices[0].value.toFixed(2)} */}
                                      {foodItem.prices[0].currency === " " ||
                                        foodItem.prices[0].currency === "EUR" ||
                                        foodItem.prices[0].currency === "Eur" ||
                                        foodItem.prices[0].currency === "eur" ||
                                        foodItem.prices[0].currency === "Euro"
                                        ? foodItem.prices[0].value.toFixed(2) + "€"
                                        :
                                        foodItem.prices[0].value + this.capitalize(foodItem.prices[0].currency)
                                      }
                                    </h3>)
                                )}
                              </div>
                            );
                          })}
                    </div>
                  </div>
                );
              })}
        </div>
      </>
    );
  }
}

export default MultiFoodGroup_Land;
